<template>
  <admin-wrapper>
    <v-snackbar color="error" v-model="error">{{ errorMessage }}</v-snackbar>
    <v-snackbar color="success" v-model="success">Changes saved</v-snackbar>
    <v-card flat>
      <nice-header>My store URL</nice-header>
      <v-card-text class="text-center display-2">{{ storeUrl }}</v-card-text>
      <v-card-text class="pb-10 text-center subtitle-2" v-if="accountData">
        <div>
          <div>Share this link with your customers so they can access your store</div>
          <custom-domain-dialog v-if="subscription || accountData.ltdCustomer"/>
        </div>
      </v-card-text>
    </v-card>
    <v-card flat class="my-5" v-if="store">
      <v-form v-model="valid">
        <nice-header>
          Store details
          <template v-slot:extra v-if="$vuetify.breakpoint.mdAndUp"></template>
        </nice-header>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" md="5" offset-md="1" class="py-0">
                <v-text-field
                    v-model="form.name"
                    label="Store name"
                    outlined
                    rounded
                    prepend-inner-icon="mdi-store"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="5" class="py-0">
                <v-autocomplete
                    v-model="form.currency"
                    :items="currencies"
                    label="Currency"
                    :disabled="store.currencyLocked"
                    :persistent-hint="store.currencyLocked"
                    :hint="store.currencyLocked ? 'You must remove all product pricing plans before you can change currencies' : null"
                    item-value="identifier"
                    outlined
                    rounded
                >
                  <template
                      v-slot:item="{ item }"
                  >{{ item.identifier.toUpperCase() }} ({{ item.symbol }})
                  </template>
                  <template
                      v-slot:selection="{ item }"
                  >{{ item.identifier.toUpperCase() }} ({{ item.symbol }})
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12" md="10" offset-md="1" class="py-0">
                <address-field v-model="form.address"/>
              </v-col>
              <v-col cols="12" md="10" offset-md="1" class="py-0">
                <v-textarea
                    outlined
                    auto-grow
                    rounded
                    id="store-description"
                    hint="Enter a short description of your store. This description will appear on the landing page of your Rentify store"
                    label="Store Description"
                    v-model="form.description"
                ></v-textarea>
              </v-col>
              <v-col cols="12" md="5" class="py-0" offset-md="1">
                <v-text-field
                    v-model="form.phone"
                    label="Phone number"
                    outlined
                    rounded
                    prepend-inner-icon="mdi-phone"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="5" class="py-0">
                <v-text-field
                    v-model="form.email"
                    label="Contact email address"
                    outlined
                    rounded
                    prepend-inner-icon="mdi-email"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="5" offset-md="1" class="py-0">
                <v-text-field
                    v-model="form.twitter"
                    label="Twitter URL"
                    outlined
                    rounded
                    prepend-inner-icon="mdi-twitter"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="5" class="py-0">
                <v-text-field
                    v-model="form.facebook"
                    label="Facebook page URL"
                    outlined
                    rounded
                    prepend-inner-icon="mdi-facebook"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="5" offset-md="1" class="py-0">
                <v-text-field
                    v-model="form.instagram"
                    label="Instagram URL"
                    outlined
                    rounded
                    prepend-inner-icon="mdi-instagram"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="5" class="py-0">
                <v-text-field
                    rounded
                    outlined
                    label="Amount to capture at checkout"
                    v-model="form.billingPercentage"
                    :hint="store.billingEnabled ? 'The percentage of the order value to capture at checkout. 100% means that the full value is captured' : 'Please enable billing to change this value'"
                    :persistent-hint="!store.billingEnabled"
                    :disabled="!store.billingEnabled"
                    prefix="%"
                    :rules="[rules.required, rules.numeric, rules.percentage]"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="10" offset-md="1" class="py-0">
                <v-text-field
                    rounded
                    outlined
                    label="Max delivery radius"
                    :suffix="store.defaultUnits"
                    v-model="form.maxDeliveryRadius"
                    prepend-inner-icon="mdi-map-marker"
                    :rules="[rules.moreThanZero]"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="5" offset-md="1" class="py-0">
                <v-subheader class="pl-0">Distance units</v-subheader>
                <v-radio-group v-model="form.defaultUnits" class="mt-0">
                  <v-radio
                      label="Kilometers"
                      value="KM"
                  ></v-radio>
                  <v-radio
                      label="Miles"
                      value="miles"
                  ></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              rounded
              outlined
              color="primary"
              :disabled="!valid"
              :loading="loading"
              @click="save"
          >Save changes
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>

    <v-card flat v-if="store">
      <v-form v-model="valid">
        <nice-header>Opening times</nice-header>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-switch
                    v-model="form.disableTimePickers"
                    label="Disable time pickers in this store"
                    hint="Turn this on if you don't want your customers to have to choose a time when renting items from your store"
                    persistent-hint
                ></v-switch>
              </v-col>
              <v-col
                  cols="2"
                  offset="2"
                  class="subtitle-1"
                  v-if="$vuetify.breakpoint.mdAndUp"
              >Day of week
              </v-col>
              <v-col cols="2" class="subtitle-1" v-if="$vuetify.breakpoint.mdAndUp">Opening time</v-col>
              <v-col cols="2" class="subtitle-1" v-if="$vuetify.breakpoint.mdAndUp">Closing time</v-col>
              <v-col cols="1" class="subtitle-1" v-if="$vuetify.breakpoint.mdAndUp">Open?</v-col>
              <v-col cols="2" v-if="$vuetify.breakpoint.mdAndUp"></v-col>

              <v-col cols="12" md="8" offset="2" class="mb-2" v-if="$vuetify.breakpoint.mdAndUp">
                <v-divider></v-divider>
              </v-col>

              <v-col auto v-if="$vuetify.breakpoint.mdAndUp"></v-col>
              <template v-for="i in [0, 1, 2, 3, 4, 5, 6]">
                <v-col
                    cols="12"
                    md="2"
                    offset-md="2"
                    :class="$vuetify.breakpoint.mdAndUp ? 'py-0' : null"
                    :key="`label-${i}`"
                >
                  <h4 class="subtitle-1 pt-2">{{ i | toDay }}</h4>
                </v-col>
                <v-col cols="12" md="2" :key="`start-${i}`" class="py-0">
                  <time-picker
                      :id="`${i}-open`"
                      :dense="$vuetify.breakpoint.mdAndUp"
                      :label="$vuetify.breakpoint.xsOnly ? 'Opening time' : null"
                      v-model="times[i].openingTime"
                      :disabled="!times[i].open"
                  ></time-picker>
                </v-col>
                <v-col cols="12" md="2" :key="`end-${i}`" class="py-0">
                  <time-picker
                      :id="`${i}-close`"
                      :dense="$vuetify.breakpoint.mdAndUp"
                      :label="$vuetify.breakpoint.xsOnly ? 'Closing time' : null"
                      v-model="times[i].closingTime"
                      :disabled="!times[i].open"
                  ></time-picker>
                </v-col>
                <v-col cols="12" md="2" :key="`checkbox-${i}`" class="py-0">
                  <v-switch
                      :id="`${i}-switch`"
                      dense
                      class="mt-1"
                      v-model="times[i].open"
                      :label="$vuetify.breakpoint.xsOnly ? 'Open?' : null"
                  ></v-switch>
                </v-col>
                <v-col cols="2" :key="`spacer-${i}`" class="py-0"></v-col>
              </template>
            </v-row>
          </v-container>
        </v-card-text>
      </v-form>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            rounded
            outlined
            color="primary"
            :disabled="!valid"
            :loading="loading"
            @click="save"
        >Save times
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-card flat class="my-5">
      <nice-header>One-off closures</nice-header>
      <v-card-text v-if="store">
        You can add one-off store closures, for things like national holidays, to prevent your customers
        from collecting/returning items on days when your store is closed
        <v-row class="justify-center align-center">
          <v-col cols="12" md="4">
            <v-list subheader two-line v-if="store.closures.length > 0">
              <v-subheader class="subtitle-1">Store closures</v-subheader>
              <v-divider></v-divider>
              <template v-for="(date, i) in store.closures">
                <v-list-item :key="`item-${i}`">
                  <v-list-item-content>
                    <v-list-item-title>{{ date | niceDate }}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <delete-closure :date="date" @delete="refetch"></delete-closure>
                  </v-list-item-action>
                </v-list-item>
                <v-divider :key="`divider-${i}`"></v-divider>
              </template>
            </v-list>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <one-off-closure @created="refetch"/>
      </v-card-actions>
    </v-card>

    <v-card flat class="my-5" v-if="store">
      <nice-header>Legal notices</nice-header>
      <v-card-text>
        If you have a service agreement/rental contract that you would like to display to your customers,
        you can upload it below as a PDF. Your customers will be prompted to agree to the content of these
        documents on creating an order in your store.
      </v-card-text>
      <v-row class="justify-center align-center">
        <v-col cols="12" md="4">
          <v-list
              subheader
              two-line
              v-if="store && store.legalDocuments && store.legalDocuments.length > 0"
          >
            <v-subheader class="subtitle-1">Uploaded documents</v-subheader>
            <v-divider></v-divider>
            <template v-for="(document, i) in store.legalDocuments">
              <v-list-item :key="`item-${i}`">
                <v-list-item-content>
                  <v-list-item-title>
                    <a :href="document.url">{{ document.description }}</a>
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <delete-document :document="document.id" @delete="refetch"/>
                </v-list-item-action>
              </v-list-item>
              <v-divider :key="`divider-${i}`"></v-divider>
            </template>
          </v-list>
        </v-col>
      </v-row>
      <upload-legal-notice @uploaded="refetch" :catalog="store.hashId"/>
    </v-card>

    <v-card flat class="my-5" v-if="store">
      <nice-header>Store images</nice-header>
      <v-card-text>You can upload images of your store here to feature in your store's landing page. If you don't
        provide one, we'll use your product images instead
      </v-card-text>
      <v-row class="justify-center align-center">
        <v-col v-for="(image, n) in store.images" :key="n" cols="2">
          <v-hover v-slot:default="{ hover }">
            <v-card flat tile>
              <v-fab-transition>
                <v-btn
                    v-show="hover"
                    color="error"
                    fab
                    dark
                    small
                    absolute
                    top
                    right
                    @click="deleteImage(image.id)"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-fab-transition>
              <v-img
                  @error="reloadImages"
                  :src="image.url"
                  contain
                  :lazy-src="image.url"
                  aspect-ratio="1"
              >
                <template v-slot:placeholder>
                  <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-card>
          </v-hover>
        </v-col>
      </v-row>
      <upload-store-image @uploaded="refetch" :catalog="store.hashId"/>
    </v-card>

    <v-card flat class="my-5" v-if="store">
      <nice-header>Delivery fees</nice-header>
      <delivery-fee-list :store="store" @change="refetch"/>
    </v-card>

    <custom-label-form/>

  </admin-wrapper>
</template>

<script>
import {currencies, editStore, stores, deleteStoreImage, subscription, accountData, user} from "@/graphql";
import AddressField from '@/components/AddressField.vue'
import UploadStoreImage from "@/components/UploadStoreImage.vue";
import CustomLabelForm from '@/components/CustomLabelForm.vue'
import DeliveryFeeList from "@/components/catalog/lists/DeliveryFeeList";

import moment from "moment";

export default {
  apollo: {
    stores,
    user,
    accountData,
    currencies,
    subscription
  },
  components: {
    UploadStoreImage,
    CustomLabelForm,
    AddressField,
    DeliveryFeeList
  },
  computed: {
    storeUrl() {
      if (this.store) {
        if (process.env.NODE_ENV === 'development') {
          return `http://${this.$store.state.selectedStore.toLowerCase()}.localhost.test:8081`
        } else {
          return `https://${this.$store.state.selectedStore.toLowerCase()}.rentify.store`
        }
      }
      return "";
    },
    store() {
      if (this.stores) {
        return this.stores.find(
            store => store.hashId === this.$store.state.selectedStore
        );
      }
      return null;
    }
  },
  filters: {
    toDay(value) {
      return moment()
          .day(value + 1)
          .format("dddd");
    },
    niceDate(value) {
      return moment(value).calendar();
    }
  },
  watch: {
    store(catalog) {
      if (catalog) {
        this.form.name = catalog.name;
        this.form.address = catalog.address;
        this.form.currency = catalog.currency.identifier;
        this.form.description = catalog.description;
        this.form.phone = catalog.phone;
        this.form.twitter = catalog.twitter;
        this.form.facebook = catalog.facebook;
        this.form.instagram = catalog.instagram;
        this.form.email = catalog.email;
        this.form.disableTimePickers = catalog.disableTimePickers
        this.form.billingPercentage = catalog.billingPercentage
        this.form.defaultUnits = catalog.defaultUnits
        this.form.maxDeliveryRadius = catalog.maxDeliveryRadius
        for (var i in catalog.openingHours) {
          const {openingTime, closingTime, open, day} = catalog.openingHours[
              i
              ];
          this.times[day] = {
            openingTime: moment(openingTime, "H:m:s").format("HH:mm"),
            closingTime: moment(closingTime, "H:m:s").format("HH:mm"),
            open
          };
        }
      }
    }
  },
  methods: {
    refetch() {
      this.$apollo.queries.stores.refetch();
    },
    reloadImages() {
      var self = this;
      setTimeout(self.refetch, 1000);
    },
    deleteImage(image) {
      this.$apollo
          .mutate({
            mutation: deleteStoreImage,
            variables: {
              image
            }
          })
          .catch(error => {
            this.error = true;
            this.errorMessage = error.message;
          })
          .then(result => {
            if (result) {
              this.$apollo.queries.stores.refetch();
            }
          });
    },
    save() {
      this.loading = true;
      let {
        name,
        address,
        currency,
        description,
        phone,
        twitter,
        facebook,
        instagram,
        maxDeliveryRadius,
        email,
        billingPercentage,
        disableTimePickers,
        defaultUnits,
      } = this.form;
      let latitude = null
      let longitude = null
      if (address && address.value) {
        latitude = address.value[0]
        longitude = address.value[1]
        address = address.text
      }
      let openingHours = [];
      for (var day in this.times) {
        let obj = this.times[day];
        openingHours.push({
          day: day,
          opening_time: obj.openingTime,
          closing_time: obj.closingTime,
          open: obj.open
        });
      }
      let variables = {
        name,
        openingHours,
        currency,
        description,
        phone,
        twitter,
        facebook,
        instagram,
        email,
        billingPercentage,
        maxDeliveryRadius,
        disableTimePickers,
        defaultUnits
      }
      if (latitude && longitude) {
        variables = {
          latitude,
          longitude,
          address,
          ...variables
        }
      }
      this.$apollo
          .mutate({
            mutation: editStore,
            variables
          })
          .catch(error => {
            this.error = true;
            this.errorMessage = error.message;
            this.loading = false;
          })
          .then(result => {
            if (result) {
              this.$apollo.queries.stores.refetch();
              this.loading = false;
              this.success = true;
            }
          });
    }
  },
  data: () => ({
    loading: false,
    valid: null,
    error: false,
    errorMessage: null,
    success: false,
    form: {
      name: null,
      currency: null,
      maxDeliveryRadius: null,
      address: null,
      description: null,
      phone: null,
      twitter: null,
      instagram: null,
      facebook: null,
      email: null,
      billingPercentage: 100,
      disableTimePickers: false,
      defaultUnits: 'miles'
    },
    rules: {
      required: v => !!v || 'This field is required',
      numeric: v => !isNaN(v) || 'Must be a valid number',
      percentage: v => v > 0 && v <= 100 || 'Please enter a value between 1 and 1000',
      moreThanZero: (v) => {
        if (v) {
          return v > 0 || 'Please enter a number greater than zero'
        }
        return true
      }
    },
    times: [
      {openingTime: null, closingTime: null, open: true},
      {openingTime: null, closingTime: null, open: true},
      {openingTime: null, closingTime: null, open: true},
      {openingTime: null, closingTime: null, open: true},
      {openingTime: null, closingTime: null, open: true},
      {openingTime: null, closingTime: null, open: true},
      {openingTime: null, closingTime: null, open: true}
    ]
  })
};
</script>
