<template>
    <div>
        <v-snackbar v-model="error" color="error">{{errorMessage}}</v-snackbar>
        <v-btn
            icon
            x-small
            color="error"
            @click="deleteFee"
            :loading="loading"
        >
            <v-icon>mdi-delete</v-icon>
        </v-btn>
    </div>
</template>

<script>
    import {deleteDeliveryFee as mutation} from '@/graphql'
    export default {
        name: 'DeleteFee',
        props: ['fee'],
        data: () => ({
            loading: false,
            error: false,
            errorMessage: null
        }),
        methods: {
            deleteFee () {
                this.loading = true;
                this.$apollo.mutate({
                    mutation,
                    variables: {
                        fee: this.fee.id
                    }
                }).catch((error) => {
                    this.error = true;
                    this.errorMessage = error.message.replace('GraphQL error: ', '');
                    this.loading = false;
                }).then((result) => {
                    if (result) {
                        this.loading = false;
                        this.$emit('delete')
                    }
                })
            }
        }

    }
</script>
