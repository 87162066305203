<template>
  <div>

    <v-card-text>
      For delivery orders, its possible to apply delivery fees to orders. You can use the button below
      to add fees based on the delivery distance.
      <v-row class="justify-center align-center">
        <v-col cols="12" md="4">
          <v-list subheader two-line v-if="deliveryFees && deliveryFees.length > 0">
            <v-subheader class="subtitle-1">Current delivery fees</v-subheader>
            <v-divider></v-divider>
            <template v-for="(fee, i) in deliveryFees">
              <v-list-item :key="`item-${i}`">
                <v-list-item-content>
                  <v-list-item-title>Up to {{ fee.range }} {{ store.defaultUnits }}</v-list-item-title>
                  <v-list-item-subtitle>{{ store.currency.symbol }}{{ fee.price }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <delete-delivery-fee :fee="fee" @delete="$emit('change')"></delete-delivery-fee>
                </v-list-item-action>
              </v-list-item>
              <v-divider :key="`divider-${i}`"></v-divider>
            </template>
          </v-list>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer/>
      <delivery-fee-modal :catalog="store" @change="$emit('change')"/>
      <v-spacer/>
    </v-card-actions>
  </div>
</template>

<script>

import DeliveryFeeModal from "@/components/modals/DeliveryFeeModal";
import DeleteDeliveryFee from "@/components/DeleteDeliveryFee";

export default {
  components: {DeliveryFeeModal, DeleteDeliveryFee},
  props: ['store'],
  computed: {
    deliveryFees() {
      if (this.store) {
        return this.store.deliveryFees;
      }
      return []
    }
  },
}
</script>