<template>
  <v-card flat class="my-5" v-if="store">
    <v-snackbar type="error" v-model="error">{{errorMessage}}</v-snackbar>
    <edit-tag v-model="showTagDialog" :tag="selectedTag" @change="handleChange"/>
    <delete-tag v-model="showDeleteTagDialog" :tag="selectedTag" @change="handleChange"/>
    <nice-header>Custom labels</nice-header>
    <v-form v-model="valid">
      <v-card-text>
        <v-row>
          <v-col cols="6" class="py-0">
            <v-text-field 
            rounded 
            outlined 
            v-model="form.labelOne" 
            label="Custom label one" 
            :rules="[rules.required]"
            ></v-text-field>
          </v-col>
          <v-col cols="6" class="py-0">
            <v-combobox 
              rounded
              outlined
              :search-input.sync="form.tagOne" 
              :items="store.labelOneChoices"
              label="Tags"
              placeholder="Type here to search or create new tags"
              item-text="value"
              item-value="id"
              @change="createTagOne"
            >
              <template v-slot:selection></template>
              <template v-slot:item="{ index, item }">
                <v-list-item-action>
                  <v-btn icon small @click="deleteTag(item)" color="error">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </v-list-item-action>
                <v-chip
                  label
                  small
                >
                  {{ item.value }}
                </v-chip>
                <v-spacer></v-spacer>
                <v-list-item-action>
                  <v-btn icon small @click="selectTag(item)" color="info">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </v-list-item-action>
              </template>

              <template v-slot:no-data>
                <v-list-item v-if="form.tagOne">
                  <span class="subheading">Press enter to create a new tag called</span>
                  <v-chip
                    label
                    small
                  >
                    {{form.tagOne}}
                  </v-chip>
                </v-list-item>
              </template>

            </v-combobox>
          </v-col>
          <v-col cols="6" class="py-0">
            <v-text-field :rules="[rules.required]" rounded outlined v-model="form.labelTwo" label="Custom label two"></v-text-field>
          </v-col>
          <v-col cols="6" class="py-0">
            <v-combobox 
              rounded
              outlined
              :search-input.sync="form.tagTwo" 
              :items="store.labelTwoChoices"
              label="Tags"
              placeholder="Type here to search or create new tags"
              item-text="value"
              item-value="id"
              @change="createTagTwo"
            >
              <template v-slot:selection></template>
              <template v-slot:item="{ index, item }">
                <v-list-item-action>
                  <v-btn icon small @click="deleteTag(item)" color="error">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </v-list-item-action>
                <v-chip
                  label
                  small
                >
                  {{ item.value }}
                </v-chip>
                <v-spacer></v-spacer>
                <v-list-item-action>
                  <v-btn icon small @click="selectTag(item)" color="info">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </v-list-item-action>
              </template>

              <template v-slot:no-data>
                <v-list-item v-if="form.tagTwo">
                  <span class="subheading">Press enter to create a new tag called</span>
                  <v-chip
                    label
                    small
                  >
                    {{form.tagTwo}}
                  </v-chip>
                </v-list-item>
              </template>
            </v-combobox>
          </v-col>

          <v-col cols="6" class="py-0">
            <v-text-field :rules="[rules.required]" rounded outlined v-model="form.labelThree" label="Custom label three"></v-text-field>
          </v-col>
          <v-col cols="6" class="py-0">
            <v-combobox 
              rounded
              outlined
              :search-input.sync="form.tagThree" 
              :items="store.labelThreeChoices"
              label="Tags"
              placeholder="Type here to search or create new tags"
              item-text="value"
              item-value="id"
              @change="createTagThree"
            >
              <template v-slot:selection></template>
              <template v-slot:item="{ index, item }">
                <v-list-item-action>
                  <v-btn icon small @click="deleteTag(item)" color="error">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </v-list-item-action>
                <v-chip
                  label
                  small
                >
                  {{ item.value }}
                </v-chip>
                <v-spacer></v-spacer>
                <v-list-item-action>
                  <v-btn icon small @click="selectTag(item)" color="info">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </v-list-item-action>
              </template>

              <template v-slot:no-data>
                <v-list-item v-if="form.tagThree">
                  <span class="subheading">Press enter to create a new tag called</span>
                  <v-chip
                    label
                    small
                  >
                    {{form.tagThree}}
                  </v-chip>
                </v-list-item>
              </template>
            </v-combobox>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          rounded
          outlined
          color="primary"
          :loading="loading"
          @click="saveChanges"
          :disabled="!valid"
        >
          Save changes
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import { stores, createTag, editLabels } from '@/graphql'
import { mapState } from 'vuex'
import EditTag from '@/components/EditTag.vue'
import DeleteTag from '@/components/DeleteTag.vue'

export default {
  components: { EditTag, DeleteTag },
  data: () => ({
    valid: null,
    showTagDialog: false,
    showDeleteTagDialog: false,
    selectedTag: null,
    form: {
      tagOne: null,
      tagTwo: null,
      tagThree: null,
      labelOne: null,
      labelTwo: null,
      labelThree: null,
      labelOneChoices: []
    },
    error: false,
    errorMessage: null,
    rules: {
      required: v => !!v || "This field is required"
    },
    loading: false
  }),
  apollo: { stores },
  computed: {
    ...mapState(['selectedStore']),
    store () {
      if (this.stores) {
        return this.stores.find(store => store.hashId === this.selectedStore)
      }
      return null
    }
  },
  methods: {
    saveChanges () {
      this.loading = true
      this.$apollo.mutate({
        mutation: editLabels,
        variables: {
          store: this.store.hashId,
          labels: [this.form.labelOne, this.form.labelTwo, this.form.labelThree]
        }
      }).then((result) => {
        if (result) {
          this.$apollo.queries.stores.refetch()
          this.loading = false
        }
      }).catch((error) => {
        this.error = true
        this.errorMessage = error.message.replace('GraphQL error: ', '')
        this.loading = false
      })
    },
    handleChange () {
      this.selectedTag = null
      this.showTagDialog = false
      this.showDeleteTagDialog = false
      this.$apollo.queries.stores.refetch()
    },
    selectTag (tag) {
      this.selectedTag = tag
      this.showTagDialog = true
    },
    deleteTag (tag) {
      this.selectedTag = tag
      this.showDeleteTagDialog = true
    },
    createTag (key, value) {
      return this.$apollo.mutate({
        mutation: createTag,
        variables: {
          catalog: this.store.hashId,
          key, value
        }
      })
    },
    createTagOne () {
      if (this.form.tagOne) {
        this.createTag('size', this.form.tagOne).then(() => {
          this.$apollo.queries.stores.refetch()
          this.form.tagOne = null
        })
      }
    },
    createTagTwo () {
      if (this.form.tagTwo) {
        this.createTag('gender', this.form.tagTwo).then(() => {
          this.$apollo.queries.stores.refetch()
          this.form.tagTwo = null
        })
      }
    },
    createTagThree () {
      if (this.form.tagThree) {
        this.createTag('brand', this.form.tagThree).then(() => {
          this.$apollo.queries.stores.refetch()
          this.form.tagThree = null
        })
      }
    }
  },
  watch: {
    store(store) {
      if (store) {
        this.form.labelOne = store.labelOne;
        this.form.labelTwo = store.labelTwo;
        this.form.labelThree = store.labelThree;
        this.form.labelOneChoices = store.labelOneChoices;
        this.form.labelTwoChoices = store.labelTwoChoices;
        this.form.labelThreeChoices = store.labelThreeChoices;
      }
    }
  }
};
</script>
