<template>
  <v-dialog max-width="400" v-model="dialog">
    <v-card flat>
      <nice-header>Edit tag value</nice-header>
      <v-form v-model="valid">
      <v-card-text>
        <v-text-field
          rounded
          outlined
          label="Value"
          v-model="text"
          :rules="required"
        ></v-text-field>
      </v-card-text>
      </v-form>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn 
          rounded 
          outlined 
          color="success" 
          :disabled="!valid"
          @click="editTag"
          :loading="loading"
        >Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
import { editTag } from '@/graphql'
export default {
  props: ['tag', 'value'],
  data: () => ({
    valid: null,
    text: null,
    required: [
      v=> !!v || 'Please enter a value'
    ],
    loading: false
  }),
  methods: {
    editTag () {
      this.loading = true
      this.$apollo.mutate({
        mutation: editTag,
        variables: {
          tag: this.tag.id,
          value: this.text
        }
      }).then((result) => {
        if (result) {
          this.$emit('change')
          this.loading = false
        }
      }).catch(() => {
        this.loading = false
      })
    }
  },
  computed: {
    ...mapState(['selectedStore']),
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  watch: {
    tag (tag) {
      if (tag) this.text = tag.value
    }
  }
}
</script>