var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.store)?_c('v-card',{staticClass:"my-5",attrs:{"flat":""}},[_c('v-snackbar',{attrs:{"type":"error"},model:{value:(_vm.error),callback:function ($$v) {_vm.error=$$v},expression:"error"}},[_vm._v(_vm._s(_vm.errorMessage))]),_c('edit-tag',{attrs:{"tag":_vm.selectedTag},on:{"change":_vm.handleChange},model:{value:(_vm.showTagDialog),callback:function ($$v) {_vm.showTagDialog=$$v},expression:"showTagDialog"}}),_c('delete-tag',{attrs:{"tag":_vm.selectedTag},on:{"change":_vm.handleChange},model:{value:(_vm.showDeleteTagDialog),callback:function ($$v) {_vm.showDeleteTagDialog=$$v},expression:"showDeleteTagDialog"}}),_c('nice-header',[_vm._v("Custom labels")]),_c('v-form',{model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"rounded":"","outlined":"","label":"Custom label one","rules":[_vm.rules.required]},model:{value:(_vm.form.labelOne),callback:function ($$v) {_vm.$set(_vm.form, "labelOne", $$v)},expression:"form.labelOne"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"6"}},[_c('v-combobox',{attrs:{"rounded":"","outlined":"","search-input":_vm.form.tagOne,"items":_vm.store.labelOneChoices,"label":"Tags","placeholder":"Type here to search or create new tags","item-text":"value","item-value":"id"},on:{"update:searchInput":function($event){return _vm.$set(_vm.form, "tagOne", $event)},"update:search-input":function($event){return _vm.$set(_vm.form, "tagOne", $event)},"change":_vm.createTagOne},scopedSlots:_vm._u([{key:"selection",fn:function(){return undefined},proxy:true},{key:"item",fn:function(ref){
var index = ref.index;
var item = ref.item;
return [_c('v-list-item-action',[_c('v-btn',{attrs:{"icon":"","small":"","color":"error"},on:{"click":function($event){return _vm.deleteTag(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1),_c('v-chip',{attrs:{"label":"","small":""}},[_vm._v(" "+_vm._s(item.value)+" ")]),_c('v-spacer'),_c('v-list-item-action',[_c('v-btn',{attrs:{"icon":"","small":"","color":"info"},on:{"click":function($event){return _vm.selectTag(item)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)],1)]}},{key:"no-data",fn:function(){return [(_vm.form.tagOne)?_c('v-list-item',[_c('span',{staticClass:"subheading"},[_vm._v("Press enter to create a new tag called")]),_c('v-chip',{attrs:{"label":"","small":""}},[_vm._v(" "+_vm._s(_vm.form.tagOne)+" ")])],1):_vm._e()]},proxy:true}],null,false,1810383967)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"rules":[_vm.rules.required],"rounded":"","outlined":"","label":"Custom label two"},model:{value:(_vm.form.labelTwo),callback:function ($$v) {_vm.$set(_vm.form, "labelTwo", $$v)},expression:"form.labelTwo"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"6"}},[_c('v-combobox',{attrs:{"rounded":"","outlined":"","search-input":_vm.form.tagTwo,"items":_vm.store.labelTwoChoices,"label":"Tags","placeholder":"Type here to search or create new tags","item-text":"value","item-value":"id"},on:{"update:searchInput":function($event){return _vm.$set(_vm.form, "tagTwo", $event)},"update:search-input":function($event){return _vm.$set(_vm.form, "tagTwo", $event)},"change":_vm.createTagTwo},scopedSlots:_vm._u([{key:"selection",fn:function(){return undefined},proxy:true},{key:"item",fn:function(ref){
var index = ref.index;
var item = ref.item;
return [_c('v-list-item-action',[_c('v-btn',{attrs:{"icon":"","small":"","color":"error"},on:{"click":function($event){return _vm.deleteTag(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1),_c('v-chip',{attrs:{"label":"","small":""}},[_vm._v(" "+_vm._s(item.value)+" ")]),_c('v-spacer'),_c('v-list-item-action',[_c('v-btn',{attrs:{"icon":"","small":"","color":"info"},on:{"click":function($event){return _vm.selectTag(item)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)],1)]}},{key:"no-data",fn:function(){return [(_vm.form.tagTwo)?_c('v-list-item',[_c('span',{staticClass:"subheading"},[_vm._v("Press enter to create a new tag called")]),_c('v-chip',{attrs:{"label":"","small":""}},[_vm._v(" "+_vm._s(_vm.form.tagTwo)+" ")])],1):_vm._e()]},proxy:true}],null,false,1095942431)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"rules":[_vm.rules.required],"rounded":"","outlined":"","label":"Custom label three"},model:{value:(_vm.form.labelThree),callback:function ($$v) {_vm.$set(_vm.form, "labelThree", $$v)},expression:"form.labelThree"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"6"}},[_c('v-combobox',{attrs:{"rounded":"","outlined":"","search-input":_vm.form.tagThree,"items":_vm.store.labelThreeChoices,"label":"Tags","placeholder":"Type here to search or create new tags","item-text":"value","item-value":"id"},on:{"update:searchInput":function($event){return _vm.$set(_vm.form, "tagThree", $event)},"update:search-input":function($event){return _vm.$set(_vm.form, "tagThree", $event)},"change":_vm.createTagThree},scopedSlots:_vm._u([{key:"selection",fn:function(){return undefined},proxy:true},{key:"item",fn:function(ref){
var index = ref.index;
var item = ref.item;
return [_c('v-list-item-action',[_c('v-btn',{attrs:{"icon":"","small":"","color":"error"},on:{"click":function($event){return _vm.deleteTag(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1),_c('v-chip',{attrs:{"label":"","small":""}},[_vm._v(" "+_vm._s(item.value)+" ")]),_c('v-spacer'),_c('v-list-item-action',[_c('v-btn',{attrs:{"icon":"","small":"","color":"info"},on:{"click":function($event){return _vm.selectTag(item)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)],1)]}},{key:"no-data",fn:function(){return [(_vm.form.tagThree)?_c('v-list-item',[_c('span',{staticClass:"subheading"},[_vm._v("Press enter to create a new tag called")]),_c('v-chip',{attrs:{"label":"","small":""}},[_vm._v(" "+_vm._s(_vm.form.tagThree)+" ")])],1):_vm._e()]},proxy:true}],null,false,968677343)})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"rounded":"","outlined":"","color":"primary","loading":_vm.loading,"disabled":!_vm.valid},on:{"click":_vm.saveChanges}},[_vm._v(" Save changes ")])],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }