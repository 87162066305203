<template>
  <v-dialog max-width="400" v-model="dialog">
    <v-card flat>
      <nice-header>Delete tag</nice-header>
      <v-card-text v-if="tag">
        You are about to delete the tag, {{ tag.value }}. This action cannot be undone,
        and the tag will be removed from all assets where it is used. Proceed?
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn 
          rounded 
          outlined 
          color="error" 
          @click="deleteTag"
          :loading="loading"
        >Delete</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
import { deleteTag } from '@/graphql'
export default {
  props: ['tag', 'value'],
  data: () => ({
    valid: null,
    text: null,
    required: [
      v=> !!v || 'Please enter a value'
    ],
    loading: false
  }),
  methods: {
    deleteTag () {
      this.loading = true
      this.$apollo.mutate({
        mutation: deleteTag,
        variables: {
          tag: this.tag.id,
        }
      }).then((result) => {
        if (result) {
          this.$emit('change')
          this.loading = false
        }
      }).catch(() => {
        this.loading = false
      })
    }
  },
  computed: {
    ...mapState(['selectedStore']),
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  watch: {
    tag (tag) {
      if (tag) this.text = tag.value
    }
  }
}
</script>