<template>
  <div>
    <modal
        v-model="dialog"
        max-width="600"
        btn-text="Add delivery fee"
        :title="`Add delivery fee for ${catalog.name}`"
    >
      <v-snackbar color="error" v-model="error">{{ errorMessage }}</v-snackbar>
      <v-card-text>
        Use the below form to add delivery fees to this store. You can add different prices for different delivery
        ranges

        <v-form v-model="valid">
          <v-row>
            <v-col cols="6">
              <text-field
                  label="Price"
                  :prefix="catalog.currency.symbol"
                  v-model="form.price"
                  :rules="[rules.required, rules.float, rules.price]"
              />
            </v-col>
            <v-col cols="6">
              <text-field
                  :label="`Max range (${catalog.defaultUnits})`"
                  v-model="form.range"
                  :rules="[rules.required, rules.integer]"
              />
            </v-col>
          </v-row>
        </v-form>

      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn
            color="primary"
            rounded
            outlined
            :loading="loading"
            :disabled="!valid"
            @click="create"
        >
          Save
        </v-btn>

      </v-card-actions>
    </modal>
  </div>
</template>

<script>
import {createDeliveryFee} from '@/graphql'

const countDecimals = (value) => {
  if (value) {
    if (Math.floor(value) !== value && value.toString().includes('.')) {
      return value.toString().split(".")[1].length || 0;
    }
  }
  return 2
};

export default {
  props: ['catalog'],
  data: () => ({
    dialog: false,
    valid: null,
    form: {
      price: null,
      range: null
    },
    loading: false,
    rules: {
      required: v => !!v || v === 0 || 'This field is required',
      float: v => !isNaN(parseFloat(v)) || 'Must be a number',
      integer: v => /^\d+$/.test(v) || 'Must be a whole number',
      price: v => countDecimals(v) === 2 || 'Invalid price'
    },
    error: false,
    errorMessage: null
  }),
  methods: {
    create() {
      this.creating = true
      this.$apollo.mutate({
        mutation: createDeliveryFee,
        variables: {
          catalog: this.catalog.hashId,
          ...this.form
        }
      }).then(() => {
        this.$emit('change')
        this.dialog = false;
        this.loading = false
      }).catch((error) => {
        this.error = true;
        this.errorMessage = error.message;
        this.loading = false
      })
    }
  }
}
</script>